@use 'styles/config' as *;

.hero {
  $self: &;
  overflow: hidden;

  :global(body.dark) & {
    color: #fff;
  }

  &__segment {
    @include segment();
  }

  &__container {
    @include container();
  }

  &__header {
    position: relative;

    .hasImage & {
      margin-bottom: 4rem;

      @media (min-width: $min-720) {
        margin-bottom: 4rem * 2;

        .flush & {
          margin-bottom: 0;
        }
      }
    }

    .underline & {
      margin-bottom: 6rem * 2;

      @media (min-width: $min-720) {
        margin-bottom: 8.5rem * 2;
      }
    }

    .flush & {
      @media (min-width: $min-720) {
        margin-bottom: 10rem;
      }
    }
  }

  &__titleWrap > *,
  &__title {
    @include h2;
    position: relative;
    max-width: 64rem;

    #{ $self }:not(.hasImage):not(.underline) & {
      margin-bottom: 1em;
    }

    .underline & {
      &::after {
        content: '';
        position: absolute;
        top: calc(100% + 3rem + 2px);
        left: 0;
        height: 2px;
        width: 7.8rem;
        background: brand();

        :global(body.brand) & {
          background-color: $color-dark;
        }

        :global(body.dark) & {
          background-color: brand();
        }

        :global(body.white) & {
          background-color: brand();
        }

        @media (min-width: $min-720) {
          top: calc(100% + 6rem + 2px);
        }
      }
    }

    .underline.brand & {
      &::after {
        :global(body.white) &,
        :global(body.dark) & {
          background-color: $color-dark;
        }
      }
    }

    .underline.dark & {
      &::after {
        :global(body.white) &,
        :global(body.brand) & {
          background-color: brand();
        }
      }
    }

    .underline.white & {
      &::after {
        :global(body.dark) &,
        :global(body.brand) & {
          background-color: brand();
        }
      }
    }
  }

  &__image {
    @include reset-all;
    position: relative;
    width: 100%;
    height: 30rem;

    @media (min-width: $min-480) {
      height: 45rem;
    }

    @media (min-width: $min-720) {
      height: 60rem;
    }

    @media (min-width: $min-960) {
      height: 68rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 11.5rem;
      width: 50vw;
      transition: background-color $transition-background;
    }

    // #{ $self }.dark &,
    // #{ $self }.brand & {
    //   &::after {
    //     background-color: #fff;
    //   }
    // }

    :global(body.white) #{ $self }.dark &,
    :global(body.white) #{ $self }.brand & {
      &::after {
        background-color: #fff;
      }
    }

    :global(body.dark) #{ $self }.white &,
    :global(body.dark) #{ $self }.brand & {
      &::after {
        background-color: $color-dark;
      }
    }

    :global(body.brand) #{ $self }.dark &,
    :global(body.brand) #{ $self }.white & {
      &::after {
        background-color: brand();
      }
    }
  }

  &__caption {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -2.7rem;

    @media (min-width: $min-720) {
      top: calc(100% + 1.3rem);
      bottom: auto;
      max-width: 50%;
    }
  }

  &__img {
    // position: absolute 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image + &__heading {
    padding: 3rem 0 0 0;
    width: 100%;
    background-color: #fff;
    transition: background-color $transition-background;

    :global(body.brand) #{ $self }:not(.dark):not(.white) & {
      background-color: brand();
    }

    :global(body.dark) #{ $self }:not(.white):not(.brand) & {
      background-color: $color-dark;
    }

    :global(body.white) #{ $self }:not(.dark):not(.brand) & {
      background-color: #fff;
    }

    .brand & {
      color: brandFont($defaultColor: $color-dark);
      background-color: brand();
    }

    .dark & {
      color: #fff;
      background-color: brandDark();
    }

    .white & {
      color: $color-dark;
      background-color: #fff;
    }

    @media (min-width: $min-720) {
      position: absolute;
      bottom: 0;
      left: 0;

      padding: 5rem 5rem 0 0;
      width: 65%;
    }

    @media (min-width: $min-960) {
      width: 50%;
    }
  }
}
