@use 'styles/config' as *;
@use "sass:math";

.text {
  $self: &;

  &.product {
    margin-bottom: 5rem;

    @media (min-width: $min-720) {
      margin-bottom: 8rem;
    }
  }

  &.hasImage {
    @include segment;

    &:first-child {
      padding-top: 0rem;

      @media (min-width: $min-720) {
        padding-top: 4rem;
        padding-bottom: 0;
      }
    }

    &.white {
      :global(body.white) & {
        @include segment-bottom(0, 0);
      }
    }

    &.brand {
      :global(body.brand) & {
        @include segment-bottom(0, 0);
      }
    }

    &.dark {
      :global(body.dark) & {
        @include segment-bottom(0, 0);
      }
    }
  }

  &__segment {
    .full & {
      @include segment();
    }

    .split & {
      @include split-segment();
    }

    .article & {
      @include article-segment();
    }

    .hasImage & {
      @include segment-bottom();
      // @include nav-offset-margin();
      overflow: hidden;
    }

    .hasImage.brand & {
      background-color: brand();
      color: $color-dark;
    }

    .brand & {
      :global(.adalvo) & {
        background-color: brand();
        color: brandFont($defaultColor: $color-copy);
      }
    }

    .hasImage.dark & {
      color: #fff;
      background-color: brandDark();
    }

    .hasImage.white & {
      background-color: #fff;
      color: $color-dark;
    }
  }

  &__container {
    .full &,
    .hasImage &,
    .article & {
      @include container();
    }
  }

  &__row {
    @include grid-row();
  }

  &__layout {
    @include grid-col();

    .full &,
    .article & {
      @media (min-width: $min-960) {
        @include grid-col(10);
        @include grid-offset-left(1);
      }
    }
  }

  &__indent {
    @include grid-col();

    .full:not(.twocol):not(.threecol) &,
    .article:not(.twocol):not(.threecol) & {
      @media (min-width: $min-720) {
        @include grid-col(10);
        @include grid-offset-left(1);
      }

      @media (min-width: $min-960) {
        @include grid-col(percentage(math.div(10, 10)));
        @include grid-offset-left(percentage(math.div(1, 10)));
      }

      @media (min-width: $min-1080) {
        @include grid-col(percentage(math.div(8, 10)));
        @include grid-offset-left(percentage(math.div(1, 10)));
      }
    }

    .hasImage:not(.twocol):not(.threecol) & {
      @media (min-width: $min-720) {
        @include grid-col(10);
        @include grid-offset-left(1);
      }

      @media (min-width: $min-1080) {
        @include grid-col(8);
        @include grid-offset-left(1);
      }
    }

    .hasImage.twocol &,
    .hasImage.threecol & {
      @media (min-width: $min-1080) {
        @include grid-col(11);
        @include grid-offset-left(1);
      }
    }
  }

  &__copy {
    @include render-copy();

    :global(.Superscript) {
      vertical-align: super;
      font-size: max(0.7em, 1.2rem);
    }

    :global(.Subscript) {
      vertical-align: sub;
      font-size: max(0.7em, 1.2rem);
    }

    :global(.Footnote) {
      font-size: max(0.8em, 1.2rem);
    }

    :global(.Disclaimer) {
      font-size: max(0.75em, 1.2rem);
      color: $color-copy;
      opacity: 0.9;
    }

    #{$self}:not(.twocol):not(.twocol):not(.threecol).hasImage:first-child & {
      p {
        font-size: responsive(1.8rem, 2.4rem);
      }
    }

    .twocol & {
      @media (min-width: $min-640) {
        columns: 2;
        column-gap: $gutter * 2;
      }
      @media (min-width: $min-1200) {
        columns: 2;
        column-gap: $gutter * 4;
      }
    }

    .threecol & {
      @media (min-width: $min-640) {
        columns: 2;
        column-gap: $gutter * 2;
      }

      @media (min-width: $min-1200) {
        columns: 3;
        column-gap: $gutter * 3;
      }
    }

    .full.threecol & {
      @media (min-width: $min-1200) {
        columns: 3;
        column-gap: $gutter * 2;
      }
    }

    .brand & {
      p {
        color: $color-font;
      }
      :global(.adalvo) & {
        p {
          color: brandFont($defaultColor: $color-copy);
        }
      }
    }

    .hasImage.brand & {
      p {
        color: brandFont();
      }
    }

    .dark & {
      :global(body.brand) & {
        p {
          color: brandFont($defaultColor: $color-copy);
        }
      }
    }

    .dark & {
      :global(.almatica) & {
        p {
          color: brandFont();
        }
      }
    }
  }

  &__buttons {
    margin-top: $gutter * 1.5;
    margin-bottom: -$gutter;

    @media (min-width: $min-720) {
      margin-top: $gutter * 2;
    }
  }
}
